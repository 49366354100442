<template>
  <div>
    <slot />
  </div>
</template>

<script>
// middleware
// definePageMeta({
//   middleware: ["auth"],
// });
export default {
  middleware: ["auth"],
  data() {
    return {
      isSmallScreen: false
    };
  },
  mounted() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      const screenWidth = window.innerWidth;
      // this.isSmallScreen = screenWidth < 24 * 96;
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreenSize);
  }
}
</script>